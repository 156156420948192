/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: Montserrat-Bold;
    src: url(./assets/fonts/Montserrat-Bold.ttf);
}
@font-face {
    font-family: Montserrat-Medium;
    src: url(./assets/fonts/Montserrat-Medium.ttf);
}

html {
    background-color: #db94b4;
    font-size: 1rem;
}
